import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1, Paragraph } from "../../styles/TextStyles"

interface ReviewSectionProps {
  title?: string
}

const ReviewSection: FunctionComponent<ReviewSectionProps> = props => {
  const { title = " Reviews" } = props
  return (
    <Wrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Subtitle>Trusted by hundreds of customers</Subtitle>
      </TextWrapper>
    </Wrapper>
  )
}

export default ReviewSection

const Wrapper = styled.div`
  padding: 100px 80px;
  @media (max-width: 640px) {
    padding: 200px 38px;
  }
`
const TextWrapper = styled.div`
  display: grid;
  gap: 30px;
`
const Title = styled(H1)`
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 40px;
  }
`
const Subtitle = styled(Paragraph)`
  line-height: 130%;
`
