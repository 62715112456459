import React, { FunctionComponent } from "react"
import styled from "styled-components"
import ReviewCard from "../components/cards/ReviewsCard"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import Line from "../components/lines/Line"
import ReviewSection from "../components/sections/ReviewsSection"

interface ReviewsProps {
  title: string
  name: string
  content: string
}

const Reviews: FunctionComponent<ReviewsProps> = props => {
  const { title = "Reviews", name = "Keywords", content = "Education" } = props
  return (
    <Layout>
      <SEO title={title} />
      <meta name={name} content={content} />
      <LineWrapper>
        <Line />
      </LineWrapper>
      <ReviewSection />
      <ReviewCard />
    </Layout>
  )
}

export default Reviews

const LineWrapper = styled.div`
  margin: 120px 0 0 0;
  display: grid;
  justify-content: center;
`
