export const reviewsData = [
  {
    description:
      "Loved my experience with them, group of professionals, ans super friendly stuff.",
    name: "Mukuakuiza Morais",
    stars: "/assets/icons/star.png",
    logo: "/assets/icons/google.svg",
  },
  {
    description:
      "Straight to the point, very professional and they give you what you need. I Definitely recommend the company.",
    name: "Dorivaldo Pina",
    stars: "/assets/icons/star.png",
    logo: "/assets/icons/google.svg",
  },
  {
    description:
      "Well organised and prompt to service. Always enjoy my experience with Joshua Organisation.",
    name: "Leopold Mombeshora",
    stars: "/assets/icons/star.png",
    logo: "/assets/icons/google.svg",
  },
  {
    description:
      "Great company, they are experts on the services they offer specially immigration and accommodation. Would recommend any day.",
    name: "Edvaldo Domingos",
    stars: "/assets/icons/star.png",
    logo: "/assets/icons/google.svg",
  },
  {
    description:
      "Joshua Organization is a company fully committed to meeting the needs of its customers. It holds International ISO Certifications, and provides services with a high degree of quality. All the services they have provided me so far have been a success. If you are looking for visa services for South Africa, Joshua Organization is the way to go.",
    name: "Adilson Ndala",
    stars: "/assets/icons/star.png",
    logo: "/assets/icons/google.svg",
  },
  {
    description:
      "Joshua Organization is a company fully committed to meeting the needs of its customers. It holds International ISO Certifications, and provides services with a high degree of quality. All the services they have provided me so far have been a success. If you are looking for visa services for South Africa, Joshua Organization is the way to go.",
    name: "Hugo Lemos",
    stars: "/assets/icons/star.png",
    logo: "/assets/icons/google.svg",
  },
]
