import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { reviewsData } from "../../data/ReviewsData"
import { themes } from "../../styles/ColorStyles"
import { H4 } from "../../styles/TextStyles"

interface ReviewCardProps {}

const ReviewCard: FunctionComponent<ReviewCardProps> = () => {
  return (
    <Wrapper>
      {reviewsData.map((review, index) => (
        <Card key={index}>
          <Description>{review.description} </Description>
          <Title>{review.name}</Title>
          <StarWrapper>
            <Stars src={review.stars} />
            <Stars src={review.stars} />
            <Stars src={review.stars} />
            <Stars src={review.stars} />
            <Stars src={review.stars} />
          </StarWrapper>
          <Logo src={review.logo} />
        </Card>
      ))}
    </Wrapper>
  )
}

export default ReviewCard

const Wrapper = styled.div`
  padding: 0px 0 100px 0;
  display: grid;
  grid-template-columns: 400px 400px 400px;
  justify-content: center;
  gap: 30px;

  @media (max-width: 1024px) {
    padding: 0px 0 100px 0;
    grid-template-columns: 325px 325px;
  }
  @media (max-width: 640px) {
    padding: 0px 0 100px 0;
    grid-template-columns: 325px;
  }
`
const Card = styled.div`
  padding: 20px;
  display: grid;
  gap: 20px;
  width: 400px;
  @media (max-width: 1024px) {
    width: 325px;
  }
  @media (max-width: 640px) {
    width: 325px;
  }

  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
`
const Title = styled(H4)`
  font-weight: bold;
`
const Description = styled.p`
  font-size: 17px;
  color: ${themes.light.text2};
  line-height: 130%;
`
const Logo = styled.img`
  display: grid;
  justify-self: end;
`
const Stars = styled.img`
  width: 20px;
`
const StarWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 20px 20px 20px 20px;
  gap: 10px;
`
